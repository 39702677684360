import React from "react";
import PropTypes from "prop-types";
import NavDropdown from "react-bootstrap/NavDropdown";
import { ReactComponent as SkeeperLogoSvg } from "../../assets/icons/logo-word.svg";
import { Copy } from "../UI/Copy";
import { ReactComponent as AcuteSvg } from "../../assets/icons/acute.svg";
import { ReactComponent as WebHookSvg } from "../../assets/icons/webhook.svg";
import { ROUTES } from "../../constants/routes";
import { NavLink } from "react-router-dom";
import "./style.scss";

export default function Header(props) {
  const sidebarItems = [
    {
      icon: <AcuteSvg />,
      fieldName: "TIME-BASED JOBS",
      active: false,
      path: ROUTES.ROOT,
    },
    {
      icon: <WebHookSvg />,
      fieldName: "API-BASED JOBS",
      active: false,
      path: ROUTES.API_BASED_JOBS,
    }
  ];

  return (
    <header className="header">
      <div className="header__company-name">
        <SkeeperLogoSvg />
      </div>

      <div className="header__nav">
      {sidebarItems.map((el) => {
        return (
          <NavLink key={el.fieldName} className="header__nav-link" to={el.path}>
            <span className="header__nav-icon">{el.icon}</span>
            <span className="header__nav-field-name">{el.fieldName}</span>
          </NavLink>
        );
      })}
      </div>

      <div className="header__right-section">
        <NavDropdown
          title={
            <div className="header__user-info">
              <span>{props?.user?.displayName}</span>
              <img src={props?.user?.photoURL} alt={props?.user?.displayName} />
            </div>
          }
          className="header__user-dropdown"
        >
          {props.userWalletInfo ? (
            <NavDropdown.Item as="div" className="header__info-dropdown-item">
              <div className="header__dropdown-info">
                <h3 className="header__user-name">
                  {props?.user?.displayName}
                </h3>
                <div className="header__user-balance">
                  <p>Balance</p>
                  <span>
                    {(props.userWalletInfo.stellarAccountBalance - 1).toFixed(
                      2
                    )}{" "}
                    XLM
                  </span>
                </div>
                <div className="header__user-info-wrapper">
                  <div className="header__user-wallet-wrapper">
                    <div className="header__user-wallet">
                      <p>Wallet</p>
                      <span>{props.userWalletInfo.stellarAddress}</span>
                    </div>
                    <Copy
                      wrapperClassName="header__user-wallet-copy"
                      text={props.userWalletInfo.stellarAddress}
                      tooltipText="Copy Stellar Address"
                      tooltipId={props.userWalletInfo.stellarAddress}
                    />
                  </div>
                  {props.userWalletInfo.apiKey ? (
                    <div className="header__user-api-key-wrapper">
                      <div className="header__user-wallet">
                        <p>API Key</p>
                        <span>{props.userWalletInfo.apiKey}</span>
                      </div>
                      <Copy
                        wrapperClassName="header__user-wallet-copy"
                        text={props.userWalletInfo.apiKey}
                        tooltipText="Copy API Key"
                        tooltipId={props.userWalletInfo.apiKey}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </NavDropdown.Item>
          ) : null}

          <NavDropdown.Item
            onClick={props.signOut}
            className="header__dropdown-action-item"
          >
            Log out
          </NavDropdown.Item>
        </NavDropdown>
      </div>
    </header>
  );
}

Header.propTypes = {
  user: PropTypes.object,
};
