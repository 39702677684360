import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import CustomCheckBox from "../UI/CheckBox";
import { Copy } from "../UI/Copy";
import ModalOverlay from "../UI/Modal";
import { getCronReadable } from "../../utils/getCronReadable";
import { getShorterString } from "../../utils/getShorterString";
import parser from "cron-parser";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { apiRequest } from "../../services/api";
import { API } from "../../constants/api";
import { toast } from "react-toastify";
import { JobModal } from "../JobModal";
import "./style.scss";

dayjs.extend(relativeTime);

export default function OperationTable({
  jobs,
  isApiBasedJobs,
  onSelect,
  isJobHistoryModalOpen,
  setIsJobHistoryModalIsOpen,
}) {
  const [jobsHistoryData, setJobsHistoryData] = useState(undefined);
  const [selectedJob, setSelectedJob] = useState(undefined);
  const [isJobsHistoryLoading, setIsJobsHistoryLoading] = useState(false);
  const [isContractDataLoading, setIsContractDataLoading] = useState(false);
  const [args, setArgs] = useState([]);

  if (!jobs) {
    return null;
  }

  const fetchContractData = async (contractId, argTo, argVal) => {
    setIsContractDataLoading(true);
    try {
      const data = await apiRequest({
        method: "POST",
        url: API.getContractSpec,
        params: {
          contractId: contractId,
        },
      });

      const args = [];

      data?.functions?.[0]?.inputs.forEach((input, index) => {
        args.push({ key: input.name, value: index === 0 ? argTo : argVal });
      });

      setArgs(args);

      setIsContractDataLoading(false);
    } catch (error) {
      toast.error("Something went wrong, Try again later.");
      setIsContractDataLoading(false);
    }
  };

  const onJobClick = async (activeJob) => {
    const argTo = activeJob?.action?.args?.[0];
    const argVal = activeJob?.action?.args?.[1];
    fetchContractData(activeJob?.action?.contractId, argTo, argVal);
    setIsJobsHistoryLoading(true);
    setIsJobHistoryModalIsOpen(true);
    setSelectedJob(activeJob);
    try {
      const data = await apiRequest({
        method: "GET",
        url: API.readExecutions,
        params: { jobId: activeJob.id, take: 10 },
      });
      setIsJobsHistoryLoading(false);
      setJobsHistoryData(data);
    } catch (error) {
      toast.error("Something went wrong, couldn't fetch jobs history data.");
      setIsJobsHistoryLoading(false);
    }
  };

  return (
    <>
      <ModalOverlay
        title={"JOB HISTORY"}
        modalIsOpen={isJobHistoryModalOpen}
        closeModal={() => setIsJobHistoryModalIsOpen(false)}
      >
        <JobModal
          jobsHistoryData={jobsHistoryData}
          selectedJob={selectedJob}
          isJobsHistoryLoading={isJobsHistoryLoading}
          args={args}
          isContractDataLoading={isContractDataLoading}
        />
      </ModalOverlay>
      <Table responsive className="operations-table">
        <thead className="operations-table__header">
          <tr>
            <th></th>
            <th>JOB NAME</th>
            <th>CONTACT ADDRESS</th>
            <th>CONTRACT FUNCTION</th>
            {!isApiBasedJobs ? (
              <>
                <th>JOB INTERVAL</th>
                <th>NEXT SCHEDULED JOB</th>
                <th>PREVIOUS JOB</th>
              </>
            ) : null}
          </tr>
        </thead>
        <tbody className="operations-table__body">
          {jobs.map((job, index) => {
            const options = {
              currentDate: dayjs().isAfter(dayjs(job.startAt))
                ? dayjs().toString()
                : dayjs(job.startAt).toString(),
              utc: true,
            };

            const interval = !isApiBasedJobs
              ? parser.parseExpression(job.schedule, options)
              : null;

            const isPrevJobHidden = !isApiBasedJobs
              ? dayjs(interval.prev(job.schedule).toString()).isBefore(
                  dayjs(job.startAt)
                )
              : null;

            const isNextJobHidden = !isApiBasedJobs
              ? dayjs().isAfter(dayjs(job.endAt))
              : null;

            return (
              <tr key={job.id}>
                <td id="table-data">
                  <CustomCheckBox
                    index={index}
                    value={job.isSelected}
                    onChange={() => onSelect(job.id)}
                  />
                </td>
                <td className="cursor-pointer" onClick={() => onJobClick(job)}>
                  {job.name}
                </td>
                <td>
                  <div className="operations-table__contract-id ">
                    <p
                      className="cursor-pointer"
                      onClick={() => onJobClick(job)}
                    >
                      {getShorterString(job.action.contractId)}
                    </p>
                    <Copy
                      text={job.action.contractId}
                      tooltipText="Copy Contract ID"
                      tooltipId={job.action.contractId}
                    />
                  </div>
                </td>
                <td className="cursor-pointer" onClick={() => onJobClick(job)}>
                  {job.action.functionName}
                </td>
                {!isApiBasedJobs ? (
                  <>
                    <td
                      className="cursor-pointer"
                      onClick={() => onJobClick(job)}
                    >
                      {getCronReadable(job.schedule)}
                    </td>
                    <td
                      className="cursor-pointer"
                      onClick={() => onJobClick(job)}
                    >
                      {isNextJobHidden
                        ? ""
                        : dayjs(
                            interval.next(job.schedule).toString()
                          ).fromNow()}
                    </td>
                    <td
                      className="cursor-pointer"
                      onClick={() => onJobClick(job)}
                    >
                      {isPrevJobHidden
                        ? ""
                        : dayjs(
                            interval.prev(job.schedule).toString()
                          ).fromNow()}
                    </td>
                  </>
                ) : null}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}
