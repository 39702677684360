import React, { useCallback, useState } from "react";
import parser from "cron-parser";
import Separator from "../UI/Separator";
import CustomInput from "../UI/Input";
import CustomButton from "../UI/Button";
import CustomSelect from "../UI/CustomSelect";
import ModalOverlay from "../UI/Modal";
import CronJobModal from "../CronJobModal";
import { DatePickerCustom } from "../UI/DatePickerCustom";
import { API } from "../../constants/api";
import { apiRequest } from "../../services/api";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import clsx from "clsx";
import { Loading } from "../Loading";
import { JOB_TYPE } from "../../constants/jobs";
import {
  getComplexTypeExample,
  getCreateJobData,
  isComplexType,
  isDataInvalid,
} from "./utils";
import { ReactComponent as InfoSvg } from "../../assets/icons/info.svg";
import { Tooltip } from "react-tooltip";
import "./style.scss";

dayjs.extend(utc);

const getEndDate = ({ numberOfJobs, startDate, frequencyCron }) => {
  if (!numberOfJobs || !startDate || !frequencyCron) {
    return null;
  }

  const interval = parser.parseExpression(frequencyCron, {
    currentDate: startDate,
    utc: true,
    iterator: true,
  });
  let lastDate = undefined;
  for (let i = 0; i < numberOfJobs; i++) {
    lastDate = interval.next();
  }

  return lastDate.value.toString();
};

export default function CreateNewJob({ jobType, onCreateComplete }) {
  const [isLoading, setLoading] = useState(false);
  const [contractId, setContractId] = useState();
  const [functions, setFunctions] = useState([]);
  const [inputs, setInputs] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const isApiBasedJobs = jobType === JOB_TYPE.apiBasedJobType;

  const [values, setValues] = useState(
    isApiBasedJobs
      ? {
          jobName: "",
          contractId: "",
          functionName: "",
          inputs: {},
        }
      : {
          jobName: "",
          contractId: "",
          functionName: "",
          inputs: {},
          numberOfJobs: "",
          frequencyCron: "",
          startDate: null,
          endDate: null,
        }
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchData = useCallback(
    debounce(async (contractId) => {
      setLoading(true);
      try {
        const data = await apiRequest({
          method: "POST",
          url: API.getContractSpec,
          params: {
            contractId: contractId,
            //TODO: For testing: "CDSJ3ETXTFPKMNJUWYNWPRDHMI6R46SHMNRFU5AGOHPNVPQK7MXTC6X4",
          },
        });

        setFunctions(data.functions);
        setContractId(contractId);
        setLoading(false);
      } catch (error) {
        setFunctions([]);
        setInputs([]);
        toast.error("Something went wrong, check if contract id is correct.");
        setLoading(false);
      }
    }, 500),
    []
  );

  const handleChange = (fieldName, value, extraKey) => {
    if (fieldName === "contractId" && value.length > 0) {
      fetchData(value);
    } else if (fieldName === "functionName") {
      const selectedFunction = functions.find((it) => it.name === value);
      setInputs(selectedFunction.inputs);
    } else if (fieldName === "inputs") {
      setValues((prev) => ({
        ...prev,
        [fieldName]: {
          ...prev[fieldName],
          [extraKey]: value,
        },
      }));
      return;
    } else if (fieldName === "startDate") {
      let lastDate = getEndDate({
        startDate: value,
        frequencyCron: values.frequencyCron,
        numberOfJobs: values.numberOfJobs,
      });

      const endDate = lastDate ? dayjs(lastDate) : null;

      setValues((prev) => ({
        ...prev,
        [fieldName]: value,
        endDate: endDate,
      }));
      return;
    } else if (fieldName === "numberOfJobs") {
      if (
        (value === "" || /^[0-9]+$/.test(value)) &&
        value !== "e" &&
        parseInt(value) !== 0
      ) {
        let lastDate = getEndDate({
          startDate: values.startDate,
          frequencyCron: values.frequencyCron,
          numberOfJobs: value,
        });

        const endDate = lastDate ? dayjs(lastDate) : null;
        setValues((prev) => ({
          ...prev,
          [fieldName]: value,
          endDate: endDate,
        }));
      }
      return;
    } else if (fieldName === "frequencyCron") {
      let lastDate = getEndDate({
        startDate: values.startDate,
        frequencyCron: value,
        numberOfJobs: values.numberOfJobs,
      });

      const endDate = lastDate ? dayjs(lastDate) : null;

      setValues((prev) => ({
        ...prev,
        [fieldName]: value,
        endDate: endDate,
      }));
      return;
    }

    setValues((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const onCreateTask = async () => {
    setLoading(true);
    const newJob = getCreateJobData({ data: values, contractId, jobType });

    try {
      await apiRequest({
        method: "POST",
        url: API.createJob,
        data: newJob,
      });
      setLoading(false);
      onCreateComplete();
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong.", error);
    }
  };

  const functionNames = functions.map((it) => ({
    value: it.name,
    label: it.name,
  }));

  const today = dayjs();
  today.set("minutes", 0);
  const todayDate = today.toDate();

  const filterPassedTime = (time) => {
    const currentTime = dayjs();
    return dayjs(time).isAfter(currentTime);
  };

  const isButtonDisabled =
    isLoading || isDataInvalid(values, inputs, isApiBasedJobs);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div
      className={clsx("new-task-modal", {
        "new-task-modal--loading": isLoading,
      })}
    >
      {isLoading ? <Loading /> : null}
      <ModalOverlay
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        title={"CRONJOB MODAL"}
      >
        <CronJobModal
          handleChange={handleChange}
          closeModal={closeModal}
          frequencyVal={values.frequencyCron}
        />
      </ModalOverlay>
      <CustomInput
        title="Job Name"
        fieldName="jobName"
        placeholder="Enter Job Name..."
        defaultValue={values.jobName}
        onChange={handleChange}
      />
      <CustomInput
        title="Execute contract"
        fieldName="contractId"
        placeholder="0xdAC17F958D2ee523a2206206994597C13D831ec7"
        defaultValue={values.contractId}
        onChange={handleChange}
      />
      <Separator />
      {functions.length ? (
        <>
          <CustomSelect
            fieldName="functionName"
            onChange={handleChange}
            title="Select Function"
            options={functionNames}
          />
          <Separator />
        </>
      ) : null}
      {inputs.length
        ? inputs.map((it) => {
            if (isComplexType(it.type)) {
              const tooltipContent = getComplexTypeExample(it.type);
              return (
                <div>
                  <p className="new-task-modal__textarea-label">
                    {it.type?.type === "Buffer" ? it.type?.type : it.type}
                    <InfoSvg data-tooltip-id={it.name} />
                    <Tooltip
                      place="top"
                      content={tooltipContent}
                      id={it.name}
                      className="new-task-modal__complex-arg-tooltip"
                    />
                  </p>
                  <textArea
                    className="new-task-modal__textarea"
                    placeholder={
                      it.type?.type === "Buffer" ? it.type?.type : it.type
                    }
                    onChange={(event) =>
                      handleChange("inputs", event.target.value, it.name)
                    }
                  >
                    {values.inputs[it.name]}
                  </textArea>
                </div>
              );
            }
            return (
              <CustomInput
                title={it.name}
                fieldName="inputs"
                placeholder={it.type}
                onChange={(fieldName, value) =>
                  handleChange(fieldName, value, it.name)
                }
                defaultValue={values.inputs[it.name]}
              />
            );
          })
        : null}

      {!isApiBasedJobs ? (
        <>
          <div className="new-task-modal__date-section">
            <div className="new-task-modal__frequency">
              <div className="new-task-modal__input-group-label">Frequency</div>

              <CustomInput
                fieldName="frequencyCron"
                placeholder="Enter cron syntax"
                inputClassName="new-task-modal__frequency-input"
                defaultValue={values.frequencyCron}
                onChange={handleChange}
                onClick={() => setModalIsOpen(true)}
                type="string"
                readOnly
              />
            </div>

            <div className="new-task-modal__start-time">
              <div className="new-task-modal__input-group-label">
                Start Time
              </div>

              <div className="new-task-modal__date-picker">
                <DatePickerCustom
                  showTimeSelect
                  selected={values.startDate}
                  fieldName="startDate"
                  dateFormat="MMMM d, yyyy h:mm aa"
                  setDate={(fieldName, data) => handleChange(fieldName, data)}
                  minDate={todayDate}
                  filterTime={filterPassedTime}
                  timeIntervals={10}
                  placeholderText="Select the start date"
                />
              </div>
            </div>

            <div className="new-task-modal__end-time">
              <CustomInput
                title="Number of Jobs"
                fieldName="numberOfJobs"
                placeholder="Enter Number Of Jobs..."
                defaultValue={values.numberOfJobs}
                onChange={handleChange}
                inputMode="numeric"
                min={1}
              />
              <CustomInput
                title="End Time"
                fieldName="jobName"
                placeholder="End Time"
                inputClassName="new-task-modal__end-time-input"
                disabled={true}
                defaultValue={
                  values.endDate
                    ? values.endDate.format("MMMM D, YYYY h:mm a")
                    : ""
                }
              />
            </div>
          </div>
          <Separator />
        </>
      ) : null}

      <CustomButton
        variant="primary"
        onClick={onCreateTask}
        isDisabled={isButtonDisabled}
      >
        Create Task
      </CustomButton>
    </div>
  );
}
