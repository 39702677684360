import React from "react";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete.svg";
import CustomButton from "../Button";
import CustomCheckBox from "../CheckBox";
import "./style.scss";

export default function OperationActions({
  onSelectAll,
  onDelete,
  isDeleteButtonActive,
  isSelectAllDisabled,
  isAllSelected,
}) {
  const onSelectClick = () => {
    onSelectAll(!isAllSelected);
  };

  return (
    <div className="operation-actions">
      <CustomButton
        variant={"action"}
        onClick={onSelectClick}
        isDisabled={isSelectAllDisabled}
      >
        <CustomCheckBox
          label={"Select All"}
          id="operation-actions__checkbox"
          value={isAllSelected}
          className="operation-actions__select-all"
        />
      </CustomButton>
      <CustomButton
        variant={"action"}
        onClick={onDelete}
        isDisabled={!isDeleteButtonActive}
      >
        <DeleteIcon />
      </CustomButton>
    </div>
  );
}
