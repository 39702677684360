export const getShorterString = (
  word,
  prefixCharLengthToShow = 8,
  suffixCharLengthToShow = 8
) => {
  if (!word) {
    return "";
  }

  return `${word.slice(0, prefixCharLengthToShow)}...${word.slice(
    word.length - suffixCharLengthToShow
  )}`;
};
