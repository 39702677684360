export function SkeletonBox({ children }) {
  return (
    <div
      style={{
        display: "block",
        marginBottom: "1rem",
      }}
    >
      {children}
    </div>
  );
}
