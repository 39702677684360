import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/header";
import Operations from "../components/Operations";
import { Routes, Route } from "react-router-dom";
import { ROUTES } from "../constants/routes";
import { ToastContainer } from "react-toastify";
import { JOB_TYPE } from "../constants/jobs";
import "react-toastify/dist/ReactToastify.css";

function MainLayout(props) {
  return (
    <div className="App">
      <Header {...props} />
      <div className="main-content">
        <Routes path={ROUTES.ROOT} element={<Outlet />}>
          <Route
            path={ROUTES.ROOT}
            element={<Operations jobType={JOB_TYPE.timeBasedJobType} />}
          />
          <Route
            path={ROUTES.TIME_BASED_JOBS}
            element={<Operations jobType={JOB_TYPE.timeBasedJobType} />}
          />
          <Route
            path={ROUTES.API_BASED_JOBS}
            element={<Operations jobType={JOB_TYPE.apiBasedJobType} />}
          />
        </Routes>
      </div>
      <ToastContainer />
    </div>
  );
}

export default MainLayout;
