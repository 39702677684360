import React from "react";
import Form from "react-bootstrap/Form";
import "./style.scss";

function CustomSelect({ onChange, fieldName, title, options }) {
  const handleChange = (event) => {
    if (onChange) {
      onChange(fieldName, event.target.value);
    }
  };

  return (
    <div className="select">
      <span className="select__title">{title}</span>
      <Form.Select aria-label="Default select example" onChange={handleChange}>
        <option selected disabled>
          {title}
        </option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Select>
    </div>
  );
}

export default CustomSelect;
