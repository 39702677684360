const firebaseConfig = {
  apiKey: "AIzaSyAq8vpBXeJ8nTpvEvZCo7L9Wo5cq-ZCv0c",
  authDomain: "skeeper-xyz.firebaseapp.com",
  databaseURL: "https://skeeper-xyz-default-rtdb.firebaseio.com",
  projectId: "skeeper-xyz",
  storageBucket: "skeeper-xyz.appspot.com",
  messagingSenderId: "823203584379",
  appId: "1:823203584379:web:900595e83286ad6aa885de",
  measurementId: "G-EET3LJWX72",
};

const baseURL = "";

const config = { firebaseConfig: firebaseConfig, baseURL: baseURL };

export default config;
