const cronParser = require("cron-parser");

export const getCronReadable = (cronExpression) => {
  try {
    const interval =
      cronParser.parseExpression(cronExpression).next().getTime() -
      cronParser.parseExpression(cronExpression).prev().getTime();
    const intervalInSeconds = interval / 1000;
    if (intervalInSeconds < 60) {
      return `Runs every ${intervalInSeconds} seconds`;
    } else if (intervalInSeconds < 3600) {
      const intervalInMinutes = Math.floor(intervalInSeconds / 60);
      return `Runs every ${intervalInMinutes} minute${
        intervalInMinutes > 1 ? "s" : ""
      }`;
    } else if (intervalInSeconds < 86400) {
      const intervalInHours = Math.floor(intervalInSeconds / 3600);
      return `Runs every ${intervalInHours} hour${
        intervalInHours > 1 ? "s" : ""
      }`;
    } else {
      const intervalInDays = Math.floor(intervalInSeconds / 86400);
      return `Runs every ${intervalInDays} day${intervalInDays > 1 ? "s" : ""}`;
    }
  } catch (error) {
    return "Invalid cron expression";
  }
};


export function timeUntilNextJob(cronExpression) {
  try {
      const interval = cronParser.parseExpression(cronExpression).next().getTime() - Date.now();
      const secondsUntilNextJob = interval / 1000;
      const minutesUntilNextJob = Math.floor(secondsUntilNextJob / 60);
      const hoursUntilNextJob = Math.floor(minutesUntilNextJob / 60);
      const daysUntilNextJob = Math.floor(hoursUntilNextJob / 24);
      
      const remainingHours = hoursUntilNextJob % 24;
      const remainingMinutes = minutesUntilNextJob % 60;
      const remainingSeconds = Math.floor(secondsUntilNextJob % 60);
      
      return `${daysUntilNextJob} days, ${remainingHours} hours, ${remainingMinutes} minutes, and ${remainingSeconds} seconds until the next job.`;
  } catch (error) {
      return 'Invalid cron expression';
  }
}