import React from "react";
import Form from "react-bootstrap/Form";
import clsx from "clsx";
import "./style.scss";

export default function CustomCheckBox({
  label,
  id,
  value,
  onChange,
  disabled,
  className,
}) {
  return (
    <div className={clsx("custom-checkbox", className)} key={`inline-checkbox`}>
      <Form.Check
        inline
        name="group1"
        type={"checkbox"}
        id={id}
        label={label}
        onChange={() => onChange(!value)}
        checked={value}
        className="custom-checkbox__check"
        disabled={disabled}
      />
    </div>
  );
}
