import Table from "react-bootstrap/Table";
import dayjs from "dayjs";
import { getCronReadable } from "../../utils/getCronReadable";
import Skeleton from "react-loading-skeleton";
import { SkeletonBox } from "../SkeletonBox";
import "react-loading-skeleton/dist/skeleton.css";
import "./styles.scss";
import { Tooltip } from "react-tooltip";

export const JobModal = ({
  jobsHistoryData,
  selectedJob,
  isJobsHistoryLoading,
  args,
  isContractDataLoading,
}) => {
  return (
    <div className="jobs-wrapper">
      <div className="jobs-wrapper__header">
        <div className="jobs-wrapper__item">
          <h3>Job NAme</h3>
          <p>{selectedJob.name}</p>
        </div>
        <div className="jobs-wrapper__item">
          <h3>Job Function</h3>
          <p>{selectedJob.action.functionName}</p>
        </div>
        <div className="jobs-wrapper__item">
          <h3>Job interval</h3>
          <p>{getCronReadable(selectedJob.schedule)}</p>
        </div>
        {!isContractDataLoading ? (
          args.map((arg) => {
            const truncatedValue =
              arg.value.length > 15
                ? arg.value.substring(0, 15) + "..."
                : arg.value;

            return (
              <div key={arg.key} className="jobs-wrapper__item">
                <h3>{arg.key}</h3>
                <p data-tooltip-id={arg.value}>
                  {truncatedValue}

                  <Tooltip
                    place="top"
                    content={arg.value}
                    id={arg.value}
                    className="new-task-modal__complex-arg-tooltip"
                  />
                </p>
              </div>
            );
          })
        ) : (
          <Skeleton
            count={1}
            height={20}
            wrapper={SkeletonBox}
            baseColor="#373737"
          />
        )}
      </div>
      <div className="jobs-wrapper__jobs">
        <Table responsive className="jobs-wrapper__jobs-table">
          <thead>
            <tr>
              <th>ORDER</th>
              <th>DATE</th>
              <th>STATUS</th>
            </tr>
          </thead>

          <tbody>
            {!isJobsHistoryLoading &&
              jobsHistoryData?.map((job, index) => (
                <tr key={index}>
                  <td>{jobsHistoryData.length - index}</td>
                  <td>
                    {dayjs(parseInt(job.id)).format("DD.MM.YYYY HH:mm A")}
                  </td>
                  <td>{job.status}</td>
                </tr>
              ))}
          </tbody>
        </Table>
        {isJobsHistoryLoading ? (
          <div className="operations__skeleton-wrapper">
            <Skeleton
              count={3}
              height={20}
              wrapper={SkeletonBox}
              baseColor="#373737"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};
