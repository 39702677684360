import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.scss";

export const DatePickerCustom = ({
  startDate,
  fieldName,
  setDate,
  ...props
}) => {
  const ExampleCustomTimeInput = ({ date, value, onChange }) => (
    <input
      value={value}
      onChange={(e) => onChange(e.target.value)}
      style={{ border: "solid 1px pink" }}
    />
  );

  return (
    <DatePicker
      className="date-picker-wrapper__input"
      wrapperClassName="date-picker-wrapper"
      calendarClassName="calendar"
      onChange={(date) => setDate(fieldName, date)}
      customTimeInput={<ExampleCustomTimeInput />}
      {...props}
    />
  );
};
