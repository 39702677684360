import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import "./style.scss";

export default function CustomButton({
  children,
  variant,
  className,
  onClick,
  isDisabled,
}) {
  return (
    <button
      onClick={onClick}
      className={clsx(
        `button button--${variant}`,

        className
      )}
      disabled={isDisabled}
    >
      {children}
    </button>
  );
}
CustomButton.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(["primary", "secondary","action", "close", "disabled"]),
  icon: PropTypes.element,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
};
