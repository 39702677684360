import React, { useEffect } from "react";
import Button from "../../components/UI/Button";
import { ReactComponent as GooGleSvg } from "../../assets/icons/google-logo.svg";
import { ReactComponent as SkeeperLogoSvg } from "../../assets/icons/logo-word.svg";
import "./styles.scss";

function Auth(props) {
  useEffect(() => {
    if (props.user) {
      props.navigate("/");
    }
  }, [props]);

  return (
    <div className="start-page">
      <div className="start-page__auth-card">
        <span className="start-page__line-one"></span>
        <span className="start-page__line-two"></span>
        <SkeeperLogoSvg className="start-page__skeeper" />
        <Button
          onClick={props.signIn}
          className="start-page__google-auth-button"
        >
          <>
            <GooGleSvg />
            Sign in with google
          </>
        </Button>
        <span className="start-page__line-three"></span>
        <span className="start-page__line-four"></span>
      </div>
    </div>
  );
}

export default Auth;
