import React from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";
import CustomButton from "../Button";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";
import "./styles.scss";

export default function ModalOverlay({
  children,
  modalIsOpen,
  closeModal,
  title,
}) {
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      className="custom-modal"
      overlayClassName="custom-modal-overlay"
    >
      <div className="custom-modal__header">
        <span className="custom-modal__header-title">{title}</span>
        <CustomButton onClick={closeModal} variant={"close"}>
          <CloseIcon />
        </CustomButton>
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>{children}</div>
    </Modal>
  );
}

ModalOverlay.propTypes = {
  children: PropTypes.node,
  modalIsOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  title: PropTypes.string,
};
