export const JOB_TYPE = {
  timeBasedJobType: 1,
  apiBasedJobType: 2,
};

export const JOB_STATUS = {
  active: 1,
  disabled: 2,
  expired: 3,
};
