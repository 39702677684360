import React, { useState } from "react";
import PropTypes from "prop-types";
import CustomButton from "../UI/Button";
import Cron from "react-js-cron";
import "react-js-cron/dist/styles.css";
import "./style.scss";

export default function CronJobModal({
  handleChange,
  closeModal,
  frequencyVal,
}) {
  const [value, setValue] = useState(frequencyVal);

  const handleClose = () => {
    handleChange("frequencyCron", value);
    closeModal();
  };

  return (
    <div className="cron-job-wrapper">
      <Cron
        className="cron-job-wrapper__cron"
        value={value}
        setValue={setValue}
      />
      <CustomButton variant="primary" onClick={handleClose}>
        Done
      </CustomButton>
    </div>
  );
}

CronJobModal.propTypes = {
  handleChange: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  frequencyVal: PropTypes.string.isRequired,
};
