import axios from "axios";
import { generateURL } from "../utils/generateUrl";

let AxiosInstance = axios.create();

AxiosInstance.interceptors.request.use(function (config) {
  let token = localStorage.getItem("token");
  config.headers["Authorization"] = "Bearer " + token;
  return config;
});

const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

const apiRequest = async ({ method, url, params, data = null }) => {
  const fullUrl = params ? generateURL(url, params) : url;
  try {
    const response = await AxiosInstance({
      method: method,
      url: fullUrl,
      data,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export { setAuthToken, apiRequest };
