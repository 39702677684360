/* eslint-disable no-useless-escape */
import dayjs from "dayjs";
import { JOB_TYPE } from "../../constants/jobs";
import { pick, omit } from "lodash";

export const frequencyObjectKeys = [
  "frequencyDays",
  "frequencyHours",
  "frequencyMinutes",
];

export const getCronJobString = ({ days, hours, minutes }) => {
  const hourString = hours ? `*/${hours}` : "*";
  const minuteString = minutes ? `*/${minutes}` : "*";
  const dayString = days ? `*/${days}` : "*";
  return `${minuteString} ${hourString} ${dayString} * *`;
};

export const getArgs = (args) => {
  return Object.values(args);
};

export const getCreateJobData = ({ data, contractId, jobType }) => {
  const isApiBasedJobs = jobType === JOB_TYPE.apiBasedJobType;

  return isApiBasedJobs
    ? {
        type: jobType,
        name: data.jobName,
        action: {
          contractId: contractId,
          functionName: data.functionName,
          args: getArgs(data.inputs),
        },
      }
    : {
        type: jobType,
        name: data.jobName,
        action: {
          contractId: contractId,
          functionName: data.functionName,
          args: getArgs(data.inputs),
        },
        schedule: data.frequencyCron,
        // getCronJobString({
        //   days: data.frequencyDays,
        //   hours: data.frequencyHours,
        //   minutes: data.frequencyMinutes,
        // }),
        startAt: dayjs.utc(data.startDate).format(),
        endAt: dayjs.utc(data.endDate).format(),
      };
};

export const isDataInvalid = (data, inputs, isApiBasedJobs) => {
  const dataArray = Object.values(omit(data, frequencyObjectKeys));
  const inputsArray = Object.values(data.inputs);

  if (isApiBasedJobs) {
    if (isApiBasedJobsPayloadInvalid({ data, inputs })) {
      return true;
    }

    return false;
  }

  // if (isFrequencyInvalid(data, frequencyObjectKeys)) {
  //   return true;
  // }

  for (let i = 0; i < dataArray.length; i++) {
    if (!dataArray[i]) {
      return true;
    }
  }

  if (inputsArray.length !== inputs.length) {
    return true;
  }

  for (let i = 0; i < inputsArray.length; i++) {
    if (!inputsArray[i]) {
      return true;
    }
  }

  return false;
};

const isApiBasedJobsPayloadInvalid = ({ data, inputs }) => {
  const inputsArray = Object.values(data.inputs);

  if (!data.jobName || !data.contractId || !data.functionName) {
    return true;
  }

  if (inputsArray.length !== inputs.length) {
    return true;
  }

  for (let i = 0; i < inputsArray.length; i++) {
    if (!inputsArray[i]) {
      return true;
    }
  }
  return false;
};

export const isFrequencyInvalid = (data, frequencyObjectKeys) => {
  const frequencyObject = pick(data, frequencyObjectKeys);
  if (
    !frequencyObject.frequencyDays &&
    !frequencyObject.frequencyHours &&
    !frequencyObject.frequencyMinutes
  ) {
    return true;
  }
  return false;
};

export const isComplexType = (type) => {
  const typePrefix = type.length >= 3 ? type.slice(0, 3) : type;
  if (type?.type === "Buffer" || typePrefix === "vec" || typePrefix === "map") {
    return true;
  }

  return false;
};

export const getComplexTypeExample = (type) => {
  const typePrefix = type.length >= 3 ? type.slice(0, 3) : type;
  if (type?.type === "Buffer") {
    return `"action": {
      "contractId": "CBQCL7Y3EOZCJQEI6IBEPET6ZL3DZVIQNP5FDIS2GQ2VIPACJ4TQIS5U",
      "functionName": "struct_test",
      "args": ["{ \"b\":\"2\", \"a\":\"3\" }"]
  }`;
  } else if (typePrefix === "vec") {
    return `"action": {
      "contractId": "CBQCL7Y3EOZCJQEI6IBEPET6ZL3DZVIQNP5FDIS2GQ2VIPACJ4TQIS5U",
      "functionName": "array_test",
      "args": ["[ \"2\", \"3\" ]"]
  }`;
  } else if (typePrefix === "map") {
    return `"action": {
      "contractId": "CBQCL7Y3EOZCJQEI6IBEPET6ZL3DZVIQNP5FDIS2GQ2VIPACJ4TQIS5U",
      "functionName": "array_test",
      "args": ["[ \"2\", \"3\" ]"]
  }`;
  }

  return "";
};
