import React, { useState } from "react";
import { ReactComponent as CopySvg } from "../../../assets/icons/copy.svg";
import { Tooltip } from "react-tooltip";
import { CopyToClipboard } from "react-copy-to-clipboard";
import clsx from "clsx";
import "./styles.scss";

export const Copy = ({
  text,
  tooltipText,
  tooltipId = "copy-tooltip-id",
  wrapperClassName,
}) => {
  const [copyStatus, setCopyStatus] = useState(false);

  return (
    <div
      className={clsx("copy", wrapperClassName)}
      onMouseLeave={() => {
        setTimeout(() => {
          setCopyStatus(false);
        }, 500);
      }}
    >
      <CopyToClipboard
        onCopy={() => {
          setCopyStatus(true);
        }}
        text={text}
      >
        <CopySvg
          className="copy__icon"
          src={CopySvg}
          alt="Copy to clipboard"
          data-tooltip-id={tooltipId}
        />
      </CopyToClipboard>
      <Tooltip
        place="top"
        content={!copyStatus ? tooltipText : "Copied"}
        id={tooltipId}
      />
    </div>
  );
};
