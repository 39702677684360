export const generateURL = (baseUrl, queryParams) => {
  const queryString = Object.keys(queryParams)
    .map(
      (key) =>
        encodeURIComponent(key) + "=" + encodeURIComponent(queryParams[key])
    )
    .join("&");

  return queryString ? `${baseUrl}?${queryString}` : baseUrl;
};
