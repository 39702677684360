import React, { useEffect, useState, useCallback } from "react";
import CustomButton from "../UI/Button";
import OperationActions from "../UI/OperationActions";
import ModalOverlay from "../UI/Modal";
import OperationTable from "../Table";
import CreateNewJob from "../CreateNewJob";
import { apiRequest } from "../../services/api";
import { API } from "../../constants/api";
import { toast } from "react-toastify";
import { JOB_STATUS, JOB_TYPE } from "../../constants/jobs";
import { usePrevious } from "../../customHooks/usePrevious";
import Skeleton from "react-loading-skeleton";
import { SkeletonBox } from "../SkeletonBox";
import "react-loading-skeleton/dist/skeleton.css";
import "./style.scss";

export default function Operations({ jobType }) {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isJobHistoryModalOpen, setIsJobHistoryModalIsOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [jobs, setJobs] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const prevJobType = usePrevious(jobType);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await apiRequest({
        method: "GET",
        url: API.readJobs,
        params: {
          take: 20,
          type: jobType,
          status: JOB_STATUS.active,
        },
      });
      const jobs = data.map((it) => ({ ...it, isSelected: false }));
      setJobs(jobs);
      setIsLoading(false);
    } catch (error) {
      toast.error("Something went wrong, couldn't fetch jobs.");
      setIsLoading(false);
    }
  }, [jobType]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onJobSelect = (id) => {
    setJobs((prev) =>
      prev.map((job) => {
        if (job.id === id) {
          return { ...job, isSelected: !job.isSelected };
        }

        return job;
      })
    );
  };

  const openModal = () => {
    if (isJobHistoryModalOpen) {
      setIsJobHistoryModalIsOpen(false);
    }

    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const onSelectAll = (isAllSelected) => {
    setJobs((prev) =>
      prev.map((job) => {
        return { ...job, isSelected: isAllSelected };
      })
    );
  };

  const onDelete = async () => {
    const jobIds = jobs.filter((job) => job.isSelected).map((job) => job.id);

    try {
      setJobs(jobs.filter((job) => !job.isSelected));
      setIsConfirmationModalOpen(false);
      await apiRequest({
        method: "POST",
        url: API.cancelJob,
        data: { jobIds: jobIds },
      });
    } catch (error) {
      toast.error("Something went wrong, couldn't cancel jobs.");
    }
  };

  const isApiBasedJobs = jobType === JOB_TYPE.apiBasedJobType;
  const isDeleteButtonActive = (jobs || []).find((it) => it.isSelected);
  const isAllNotSelected =
    (jobs || []).find((it) => !it.isSelected) || !jobs?.length;
  const loading = isLoading || prevJobType !== jobType;

  return (
    <div className="operations">
      <ModalOverlay
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        title={"NEW TASK"}
      >
        <CreateNewJob
          onCreateComplete={() => {
            setIsOpen(false);
            fetchData();
          }}
          jobType={jobType}
        />
      </ModalOverlay>

      <ModalOverlay
        modalIsOpen={isConfirmationModalOpen}
        closeModal={closeModal}
        title="Are you sure you want to delete selected jobs?"
      >
        <div className="operations__confirm-actions">
          <CustomButton
            onClick={() => setIsConfirmationModalOpen(false)}
            variant="secondary"
          >
            Close
          </CustomButton>
          <CustomButton onClick={onDelete} variant="primary">
            Delete
          </CustomButton>
        </div>
      </ModalOverlay>

      <div>
        <div className="operations__header">
          <div className="operations__info">
            <h1>List of current jobs</h1>
          </div>
          <div>
            <CustomButton onClick={openModal} variant="primary">
              New Task
            </CustomButton>
          </div>
        </div>
      </div>
      <div className="operations__action-header">
        <OperationActions
          onSelectAll={(isAllSelected) => onSelectAll(isAllSelected)}
          isAllSelected={!isAllNotSelected}
          onDelete={() => setIsConfirmationModalOpen(true)}
          isDeleteButtonActive={!!isDeleteButtonActive}
          isSelectAllDisabled={!jobs?.length}
        />
      </div>
      {!loading ? (
        <OperationTable
          jobs={jobs}
          onSelect={onJobSelect}
          isApiBasedJobs={isApiBasedJobs}
          isJobHistoryModalOpen={isJobHistoryModalOpen}
          setIsJobHistoryModalIsOpen={setIsJobHistoryModalIsOpen}
        />
      ) : (
        <div className="operations__skeleton-wrapper">
          <Skeleton
            count={5}
            height={20}
            wrapper={SkeletonBox}
            baseColor="#373737"
          />
        </div>
      )}
    </div>
  );
}
