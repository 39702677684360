import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { ROUTES } from "../../constants/routes";

export const ProtectedRoute = ({
  user,
  redirectPath = ROUTES.AUTH,
  loading,
}) => {
  const token = localStorage.getItem("token");
  const location = useLocation();
 
  if (loading) {
    return null;
  }

  if (!user || !token) {
    return <Navigate to={redirectPath} replace state={{ from: location }} />;
  }

  return <Outlet />;
};
