import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import "./style.scss";

const CustomInput = ({
  title,
  fieldName,
  placeholder,
  onChange,
  defaultValue = "",
  inputClassName,
  ...props
}) => {
  const handleChange = (event) => {
    if (onChange) {
      onChange(fieldName, event.target.value);
    }
  };

  return (
    <div className="input">
      <span className="input__title">{title}</span>
      <input
        className={clsx("input__input", inputClassName)}
        value={defaultValue}
        placeholder={placeholder}
        onChange={handleChange}
        {...props}
      />
    </div>
  );
};

export default CustomInput;

CustomInput.propTypes = {
  title: PropTypes.string,
  inputClassName: PropTypes.string,
  defaultValue: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};
