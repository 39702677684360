import { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Auth from "./pages/Auth";
import { initializeApp } from "firebase/app";
import { Helmet } from "react-helmet";
import configs from "./configs";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import MainLayout from "./layouts/mainLayout";
import {
  addDoc,
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { ROUTES } from "./constants/routes";
import { apiRequest, setAuthToken } from "./services/api";
import { API } from "./constants/api";
import { toast } from "react-toastify";

function App() {
  const app = initializeApp(configs.firebaseConfig);
  const auth = getAuth(app);
  const db = getFirestore(app);
  const navigate = useNavigate();
  const [user, loading] = useAuthState(auth);
  const [userWalletInfo, setUserWalletInfo] = useState(null);

  const googleProvider = new GoogleAuthProvider();

  const signInWithGoogle = async () => {
    try {
      const res = await signInWithPopup(auth, googleProvider);
      const user = res.user;
      const q = query(collection(db, "users"), where("uid", "==", user.uid));
      const docs = await getDocs(q);
      if (docs.docs.length === 0) {
        await addDoc(collection(db, "users"), {
          uid: user.uid,
          name: user.displayName,
          authProvider: "google",
          email: user.email,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const signOut = async () => {
    auth
      .signOut()
      .then(() => {
        localStorage.removeItem("token");
        setAuthToken(null);
      })
      .catch((err) => {
        console.error(err);
        alert(err.message);
      });
  };

  useEffect(() => {
    if (loading) return;

    const fetchUserWalletData = async () => {
      if (userWalletInfo) {
        return;
      }

      try {
        const data = await apiRequest({
          method: "GET",
          url: API.getUserInfo,
        });

        setUserWalletInfo(data);
      } catch (error) {
        toast.error("Something went wrong, couldn't fetch user data.");
      }
    };

    if (user) {
      user.getIdToken().then((token) => {
        setAuthToken(token);
        localStorage.setItem("token", token);
        fetchUserWalletData();
      });
    }
  }, [user, loading, userWalletInfo]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Skeeper - DApp - Keepers for Stellar</title>
        <link rel="canonical" href="http://mysite.com/example" />
        <meta property="og:image" content="./" />

        <meta
          name="description"
          content="Skeeper provides smart-contract execution and automation tools and infrastructure for Soroban ecosystem"
        />
      </Helmet>
      <Routes>
        <Route
          exact
          path={ROUTES.AUTH}
          element={
            <Auth signIn={signInWithGoogle} navigate={navigate} user={user} />
          }
        />
        <Route element={<ProtectedRoute user={user} loading={loading} />}>
          <Route
            exact
            path="*"
            element={
              <MainLayout
                user={user}
                userWalletInfo={userWalletInfo}
                signIn={signInWithGoogle}
                signOut={signOut}
              />
            }
          />
        </Route>
      </Routes>
    </>
  );
}

export default App;
