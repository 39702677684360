import React from "react";
import "./styles.scss";

export const Loading = () => {
  return (
    <div className="loader">
      <span class="loader__spinner"></span>
    </div>
  );
};
